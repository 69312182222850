import React, { useState, useEffect, useCallback } from 'react';
import { addDays } from 'date-fns';
import CustomDatePicker from './datepicker';
import {
  ChartTitle,
  ReportWrapper,
  Subtitle,
  DatepickerRow,
  StyledTable,
} from './styles';
import { useQueryReport } from '../hooks/useQueryReport';

const PageviewsReport = (props) => {
  const [reportData, setReportData] = useState([]);
  const [startDate, setStartDate] = useState(addDays(new Date(), -30));
  const [endDate, setEndDate] = useState(new Date());
  const [totalPages, setTotalPages] = useState(0);

  const { fetchData } = useQueryReport();

  const displayResults = useCallback((response) => {
    const queryResult = response.reports[0].rows;
    setTotalPages(queryResult.length);
    const total = response.reports[0].totals[0].metricValues[0].value;
    let newReportData = [];
    let newData = [];
    queryResult.forEach((row, idx) => {
      if (idx < 15) {
        let tempObj = {
          path: row.dimensionValues[0].value,
          title: row.dimensionValues[1].value,
          views: row.metricValues[0].value,
          perc: `${parseFloat(
            (row.metricValues[0].value / total) * 100
          ).toFixed(1)}%`,
        };
        newReportData.push(tempObj);
      }
    });
    /* newReportData.forEach((item) => {
      newData.push(item);

      if (item.path.includes('/order_now.php')) {
        const newItem = { ...item };
        newItem.views = Math.max(0, Math.round(parseFloat(item.views) * 0.3));
        const originalPercentage = parseFloat(item.perc);
        newItem.perc = `${originalPercentage - originalPercentage * 0.7}%`;
        newItem.path = '/order_now.php?dcamp=1';
        newData.push(newItem);
      }
    }); */
    //console.log('newReportData', newReportData, newData);
    setReportData(newReportData);
  }, []);

  useEffect(() => {
    const request = {
      propertyID: props.propertyID,
      startDate,
      endDate,
      metrics: [
        {
          name: 'screenPageViews',
        },
      ],
      dimensions: [
        {
          name: 'pagePath',
        },
        {
          name: 'pageTitle',
        },
      ],

      //filter: 'screenPageViewsh!@localhost/',
    };
    setTimeout(
      () =>
        fetchData(request)
          .then((resp) => displayResults(resp))
          .catch((error) => console.error(error)),
      1000
    );
  }, [startDate, endDate, props.propertyID, fetchData, displayResults]);

  return (
    <ReportWrapper>
      <ChartTitle>Top 15 Pages by Views</ChartTitle>
      <Subtitle>{`Total pages - ${totalPages}`}</Subtitle>
      <DatepickerRow>
        <CustomDatePicker
          placeholder={'Start date'}
          date={startDate}
          handleDateChange={(date) => setStartDate(date)}
        />
        <CustomDatePicker
          placeholder={'End date'}
          date={endDate}
          handleDateChange={(date) => setEndDate(date)}
        />
      </DatepickerRow>
      {reportData.length && (
        <StyledTable>
          <thead>
            <tr>
              <th>Page</th>
              <th>Views</th>
              <th>%</th>
            </tr>
          </thead>
          <tbody>
            {reportData.map((row, id) => (
              <tr key={id}>
                <td className="left-align">
                  {row.path.includes('cpc') ? (
                    <span className="badge text-bg-success p-2">
                      Google ads
                    </span>
                  ) : (
                    <>
                      <strong> Page Title :</strong> {row.title}
                      <br />
                      <strong> Path :</strong>{' '}
                      <a
                        href={`https://hibouair.com${row.path}`}
                        target="_blank"
                      >
                        {row.path}
                      </a>
                    </>
                  )}
                </td>
                <td>{row.views}</td>
                <td>{row.perc}</td>
              </tr>
            ))}
          </tbody>
        </StyledTable>
      )}
    </ReportWrapper>
  );
};

export default PageviewsReport;
