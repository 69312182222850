import React, { useState, useEffect, useCallback } from 'react';
import 'chart.js/auto';
import { Line } from 'react-chartjs-2';
import { addDays } from 'date-fns';
import {
  ChartWrapper,
  ReportWrapper,
  ChartTitle,
  Subtitle,
  DatepickerRow,
} from './styles';
import CustomDatePicker from './datepicker';
import { formatDate } from './utils';
import { useQueryReport } from '../hooks/useQueryReport';

const DayVisitsReport = (props) => {
  const INITIAL_STATE = {
    labels: [],
    values: [],
  };
  const [reportData, setReportData] = useState(INITIAL_STATE);
  const [startDate, setStartDate] = useState(addDays(new Date(), -30));
  const [endDate, setEndDate] = useState(new Date());
  const [average, setAverage] = useState(0);
  const [total, setTotal] = useState(0);
  const [salesData, setSalesData] = useState([]);

  const { fetchData } = useQueryReport();

  const displayResults = useCallback((response) => {
    const queryResult = response.reports[0].rows;
    let tt = response.reports[0].totals[0].metricValues[0].value;
    setTotal(tt);
    setAverage(parseInt(tt / response.reports[0].rowCount));
    let labels = [];
    let values = [];

    queryResult.forEach((row) => {
      labels.push(formatDate(row.dimensionValues[0].value));
      values.push(row.metricValues[0].value);
    });

    setReportData((prev) => ({
      ...prev,
      labels,
      values,
    }));
  }, []);
  useEffect(() => {
    if (props.propertyID == '376181489') {
      let sd = new Date(startDate).toISOString().split('T')[0];
      let ed = new Date(endDate).toISOString().split('T')[0];
      var requestOptions = {
        method: 'GET',
        redirect: 'follow',
      };
      let theUrl =
        'https://www.bleuio.com/salesapp/api/v1/getsalesbleuio?start_date=' +
        sd +
        '&end_date=' +
        ed;
      fetch(theUrl, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          var newDataPoints = [];
          reportData.labels.forEach((label) => {
            var matchedData = result.find(
              (item) => item.order_date_formatted === label
            );
            if (matchedData) {
              newDataPoints.push(matchedData.total_quantity);
            } else {
              newDataPoints.push(null); // Inserting null for missing data
            }
          });
          setSalesData(newDataPoints);
        })
        .catch((error) => console.log('error', error));
    }
  }, [reportData]);

  var data = {
    labels: reportData.labels,
    datasets: [
      {
        label: `${props.title} per day `,
        fill: false,
        lineTension: 0.3,
        borderColor: '#35213d',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: '#375751',
        pointHoverBorderColor: 'rgba(220,220,220,1)',
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: reportData.values,
      },
    ],
  };
  if (salesData && salesData.length > 0 && props.propertyID == '376181489') {
    data.datasets.push({
      label: 'Sales',
      type: 'bar',
      backgroundColor: '#0d4a8d',
      borderColor: '#0d4a8d',
      borderWidth: 1,
      data: salesData,
    });
  }

  const options = {
    scales: {
      yAxes: {
        suggestedMin: 0,
      },
      xAxes: {
        ticks: {
          autoSkip: true,
          maxTicksLimit: 7,
        },
      },
    },
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    plugins: {
      datalabels: {
        font: {
          size: 0,
        },
      },
    },
  };

  useEffect(() => {
    const request = {
      propertyID: props.propertyID,
      startDate,
      endDate,
      metrics: props.metric,
      dimensions: [
        {
          name: 'date',
        },
      ],
      orderBy: 'date',
    };
    fetchData(request)
      .then((resp) => displayResults(resp))
      .catch((error) => console.error(error));
  }, [
    startDate,
    endDate,
    props.propertyID,
    props.metric,
    fetchData,
    displayResults,
  ]);

  return (
    <ReportWrapper>
      <ChartTitle>{`${props.title} per day`}</ChartTitle>
      <Subtitle>{`Average - ${average} ${props.title}, Total - ${total} ${props.title} `}</Subtitle>
      <DatepickerRow>
        <CustomDatePicker
          placeholder={'Start date'}
          date={startDate}
          handleDateChange={(date) => setStartDate(date)}
        />
        <CustomDatePicker
          placeholder={'End date'}
          date={endDate}
          handleDateChange={(date) => setEndDate(date)}
        />
      </DatepickerRow>
      {reportData && (
        <ChartWrapper>
          <Line data={data} options={options} width={100} height={250} />
        </ChartWrapper>
      )}
    </ReportWrapper>
  );
};

export default DayVisitsReport;
