import React, { useState, useEffect } from 'react';
import { Pie } from 'react-chartjs-2';
import { PieChartWrapper, colors } from './styles';
import { addDays } from 'date-fns';
import CustomDatePicker from './datepicker';
import { ChartTitle, ReportWrapper, Subtitle, DatepickerRow } from './styles';
import 'chartjs-plugin-datalabels';
import { useQueryReport } from '../hooks/useQueryReport';
import DataTable from 'react-data-table-component';

const CountriesReport = (props) => {
  const INITIAL_STATE = {
    labels: [],
    values: [],
    colors: [],
  };
  const [reportData, setReportData] = useState(INITIAL_STATE);
  const [startDate, setStartDate] = useState(addDays(new Date(), -30));
  const [endDate, setEndDate] = useState(new Date());
  const [myData, setMyData] = useState([]);

  const { fetchData } = useQueryReport();

  const data = {
    labels: reportData.labels,
    datasets: [
      {
        data: reportData.values,
        backgroundColor: reportData.colors,
      },
    ],
  };

  const options = {
    tooltips: {
      callbacks: {
        label: function (tooltipItem, data) {
          return data.labels[tooltipItem['index']];
        },
      },
    },
    plugins: {
      datalabels: {
        color: 'black',
        font: {
          size: 20,
        },
        formatter: function (value, context) {
          const perc = parseInt((value / reportData.totalUsers) * 100);
          return perc + '%';
        },
      },
    },
  };

  useEffect(() => {
    const request = {
      propertyID: props.propertyID,
      startDate,
      endDate,
      metrics: [
        {
          name: 'totalUsers',
        },
      ],
      dimensions: {
        name: 'country',
      },
    };

    const displayResults = (response) => {
      const queryResult1 = response.reports[0].rows;

      const queryResult = queryResult1.filter((item) => {
        const dimensionValue = item.dimensionValues[0]?.value;
        return dimensionValue !== 'Bangladesh';
      });

      setMyData(queryResult);
      let labels = [];
      let values = [];
      let bgColors = [];
      queryResult.forEach((row, idx) => {
        if (idx < 5) {
          labels.push(row.dimensionValues[0].value);
          values.push(row.metricValues[0].value);
          bgColors.push(colors[idx + 1]);
        }
      });
      setReportData((prev) => ({
        ...prev,
        labels,
        values,
        colors: bgColors,
        totalCoutries: queryResult.length,
        totalUsers: response.reports[0].totals[0].metricValues[0].value,
        allResult: queryResult,
      }));
    };

    setTimeout(
      () =>
        fetchData(request)
          .then((resp) => displayResults(resp))
          .catch((error) => console.error(error)),
      1000
    );
  }, [startDate, endDate, props.propertyID, fetchData]);
  const columns = [
    {
      name: 'City',
      selector: (row) => row.dimensionValues[0].value,
    },
    {
      name: 'Visitors',
      selector: (row) => row.metricValues[0].value,
    },
  ];
  const pagination = {
    perPage: 5,
  };
  const handleChange = (page) => {
    setMyData(myData.slice(page * 5, (page + 1) * 5));
  };
  return (
    <ReportWrapper>
      <div className="row">
        <div className="col-md-6">
          <ChartTitle>Top 5 Countries by Users</ChartTitle>
          <Subtitle>{`Total countries - ${reportData.totalCoutries}`}</Subtitle>
          <DatepickerRow>
            <CustomDatePicker
              placeholder={'Start date'}
              date={startDate}
              handleDateChange={(date) => setStartDate(date)}
            />
            <CustomDatePicker
              placeholder={'End date'}
              date={endDate}
              handleDateChange={(date) => setEndDate(date)}
            />
          </DatepickerRow>
          {reportData && (
            <PieChartWrapper>
              <Pie data={data} options={options} />
            </PieChartWrapper>
          )}
        </div>
        <div className="col-md-6">
          {reportData &&
            reportData.allResult &&
            reportData.allResult.length > 0 && (
              <div className="card">
                <div className="card-body">
                  <h3>All visitor countries</h3>
                  <DataTable
                    data={myData}
                    columns={columns}
                    pagination={pagination}
                    onChange={handleChange}
                  />
                </div>
              </div>
            )}
        </div>
      </div>
    </ReportWrapper>
  );
};

export default CountriesReport;
