import { useAuthContext } from '../context/AuthContext';
import { format } from 'date-fns';
import { useCallback } from 'react';

export function useQueryReport() {
  const { token } = useAuthContext();

  const requestDimensions = (dimensions) => {
    let result = [];
    dimensions.forEach((item) => {
      result.push({
        name: item,
      });
    });
    return result;
  };

  const fetchData = useCallback(
    async (props) => {
      const {
        propertyID,
        startDate,
        endDate,
        metrics,
        dimensions,
        orderBy,
        filter,
      } = props;
      const requestBody = JSON.stringify({
        requests: [
          {
            dateRanges: [
              {
                startDate: format(new Date(startDate), 'yyyy-MM-dd'),
                endDate: format(new Date(endDate), 'yyyy-MM-dd'),
              },
            ],
            metricAggregations: ['TOTAL', 'MINIMUM', 'MAXIMUM'],
            metrics: metrics,
            dimensions: dimensions,
            orderBys: orderBy
              ? [
                  {
                    dimension: {
                      orderType: 'NUMERIC',
                      dimensionName: orderBy,
                    },
                    desc: false,
                  },
                ]
              : [],
            /* orderBys: orderBy
              ? [
                  {
                    fieldName: orderBy.fieldName,
                    sortOrder: orderBy.order,
                  },
                ]
              : [], */
          },
        ],
      });
      //console.log(JSON.parse(requestBody), token);

      const resp = await fetch(
        `https://analyticsdata.googleapis.com/v1beta/properties/${propertyID}:batchRunReports?key=${process.env.REACT_APP_API_KEY}`,
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: requestBody,
        }
      );
      const data = await resp.json();
      if (data.error) {
        return data.error;
      }
      return data;
    },
    [token]
  );
  return { fetchData };
}
export function useSocialQuery() {
  const fetchData = useCallback(async (props) => {
    const { site, selectedSocial, selectedType, startDate, endDate } = props;
    const resp = await fetch(
      `https://tracking.smartsensordevices.com/socialtracking/api.php?type=${selectedType}&site=${site}&social=${selectedSocial}&startDate=${format(
        new Date(startDate),
        'yyyy-MM-dd'
      )}&endDate=${format(new Date(endDate), 'yyyy-MM-dd')}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer secretTokenSSD767427449`,
        },
      }
    );
    const data = await resp.json();
    if (data.error) {
      return data.error;
    }
    return data;
  });
  return { fetchData };
}
