import React, { useEffect, useState } from 'react';
import DayVisitsReport from './dayVisitsReport';
import CountriesReport from './countriesReport';
import PageviewsReport from './pageviewReport';
import SourceReport from './sourceReport';
import BrowsersReport from './browsersReport';
import DevicesReport from './devicesReport';
import Header from '../Components/header';
import InputField from '../Components/input';
import CitiesReport from './citiesReport';
import SocialReport from './socialReport';
//import AdsPageviewsReport from './adsPageviewReport';

const DashBoard = () => {
  //const [propertyID, setPropertyID] = useState(null);
  const [propertyID, setPropertyID] = useState(
    localStorage.getItem('propertyID') || '366751290'
  );

  useEffect(() => {
    const storedpropertyID = localStorage.getItem('propertyID');
    if (storedpropertyID && storedpropertyID !== propertyID) {
      setPropertyID(storedpropertyID);
    }
  }, [propertyID]);
  const handleSelectChange = (event) => {
    const value = event.target.value;
    setPropertyID(value);
    localStorage.setItem('propertyID', value);
  };

  return (
    <>
      <Header />
      <div className="container">
        <div className="mt-2">
          {' '}
          <select
            className="form-select"
            aria-label="Default select example"
            value={propertyID}
            onChange={handleSelectChange}
          >
            <option value="366751290">Smart Sensor Devices</option>
            <option value="376181489">BleuIO</option>
            <option value="382072611">HibouAir</option>
          </select>
        </div>
        {propertyID ? (
          <>
            <div className="card shadow mt-4">
              <div className="card-body">
                <DayVisitsReport
                  metric={[
                    {
                      //expression: 'totalUsers',
                      name: 'totalUsers',
                    },
                  ]}
                  title={'Users'}
                  propertyID={propertyID}
                />
              </div>
            </div>
            <div className="card shadow mt-4">
              <div className="card-body">
                <PageviewsReport propertyID={propertyID} />
              </div>
            </div>

            <div className="card shadow mt-4">
              <div className="card-body">
                <SourceReport propertyID={propertyID} />
              </div>
            </div>

            <div className="card shadow mt-4">
              <div className="card-body">
                <CountriesReport propertyID={propertyID} />
              </div>
            </div>
            <div className="card shadow mt-4">
              <div className="card-body">
                <CitiesReport propertyID={propertyID} />
              </div>
            </div>
            <div className="card shadow mt-4">
              <div className="card-body">
                <SocialReport propertyID={propertyID} />
              </div>
            </div>

            <div className="card shadow mt-4">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-6">
                    <DevicesReport propertyID={propertyID} />
                  </div>
                  <div className="col-md-6">
                    <BrowsersReport propertyID={propertyID} />
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <InputField submitpropertyID={(id) => setPropertyID(id)} />
          </>
        )}
      </div>
    </>
  );
};

export default DashBoard;
