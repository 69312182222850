import React from 'react';
import styled from 'styled-components';
import { useAuthContext, useAuthContextAPI } from '../context/AuthContext';

const Container = styled.div`
  height: 70px;
  background: #1c2e42;

  z-index: 2;
  width: 100%;

  h1 {
    padding-left: 20px;
    color: #d1d8e0;
  }

  .signout {
    padding-right: 20px;
    color: #e0d5d1;
    cursor: pointer;
  }
`;

const Header = () => {
  const { token } = useAuthContext();
  const { onSetToken } = useAuthContextAPI();

  const onSignOut = () => {
    window.google.accounts.oauth2.revoke(token, () => {
      onSetToken('');
    });
  };

  return (
    <Container>
      <div className="container">
        <div className="row">
          <div className="col-md-7 pt-2">
            <h1>SSD website tracking system</h1>
          </div>
          <div className="col-md-5 text-end pt-3">
            {' '}
            <div className="signout" onClick={onSignOut}>
              SIGN OUT
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Header;
