import React, { useState, useEffect, useCallback } from 'react';
import { addDays } from 'date-fns';
import CustomDatePicker from './datepicker';
import {
  ChartTitle,
  ReportWrapper,
  DatepickerRow,
  ChartWrapper,
} from './styles';
import { useSocialQuery } from '../hooks/useQueryReport';
import { Line } from 'react-chartjs-2';
import { formatDate2 } from './utils';

const SocialReport = (props) => {
  const INITIAL_STATE = {
    labels: [],
    values: [],
  };
  const [reportData, setReportData] = useState(INITIAL_STATE);
  const [startDate, setStartDate] = useState(addDays(new Date(), -30));
  const [endDate, setEndDate] = useState(new Date());
  const [selectedSocial, setSelectedSocial] = useState('Facebook');
  const [selectedType, setSelectedType] = useState('post');
  const [total, setTotal] = useState(0);

  const site =
    props.propertyID === '366751290'
      ? 'ssd'
      : props.propertyID === '376181489'
      ? 'BleuIO'
      : 'HibouAir';
  const { fetchData } = useSocialQuery();
  const displayResults = useCallback((response) => {
    const totalY = response.reduce(
      (total, item) => total + parseInt(item.y),
      0
    );

    setTotal(totalY);
    let labels = [];
    let values = [];

    response.forEach((row) => {
      labels.push(formatDate2(row.x));
      values.push(row.y);
    });

    setReportData((prev) => ({
      ...prev,
      labels,
      values,
    }));
  }, []);
  const data = {
    labels: reportData.labels,
    datasets: [
      {
        label: `Social report - ${site} ${selectedSocial} ${selectedType}`,
        fill: false,
        lineTension: 0.3,
        borderColor: '#35213d',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: '#375751',
        pointHoverBorderColor: 'rgba(220,220,220,1)',
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: reportData.values,
      },
    ],
  };
  const options = {
    scales: {
      yAxes: {
        suggestedMin: 0,
      },
      xAxes: {
        ticks: {
          autoSkip: true,
          maxTicksLimit: 7,
        },
      },
    },
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    plugins: {
      datalabels: {
        font: {
          size: 0,
        },
      },
    },
  };
  const handleSocial = (e) => {
    setSelectedSocial(e);
  };
  useEffect(() => {
    const request = {
      site,
      selectedSocial,
      selectedType,
      startDate,
      endDate,
    };
    setTimeout(
      () =>
        fetchData(request)
          .then((resp) => {
            displayResults(resp);
          })
          .catch((error) => console.error(error)),
      1100
    );
  }, [
    selectedSocial,
    selectedType,
    startDate,
    endDate,
    props.propertyID,
    displayResults,
  ]);
  return (
    <ReportWrapper>
      <ChartTitle>
        Social media report {site} {selectedSocial} {selectedType}. Total{' '}
        {total}
      </ChartTitle>
      <DatepickerRow>
        <CustomDatePicker
          placeholder={'Start date'}
          date={startDate}
          handleDateChange={(date) => setStartDate(date)}
        />
        <CustomDatePicker
          placeholder={'End date'}
          date={endDate}
          handleDateChange={(date) => setEndDate(date)}
        />
        <select
          className="form-select"
          id="exampleFormControlSelect1"
          name="social_channel"
          required=""
          style={{ height: '40px', marginTop: '20px', width: '25%' }}
          onChange={(e) => {
            handleSocial(e.target.value);
          }}
        >
          <option>Facebook</option>
          <option>Twitter</option>
          <option>Instagram</option>
          <option>LinkedIn</option>
          <option>Pinterest</option>
          <option>Reddit</option>
          <option>Tumblr</option>
          <option>Quora</option>
          <option>Medium</option>
          <option>Vimeo</option>
        </select>
        <select
          className="form-select"
          id="exampleFormControlSelect1"
          name="social_channel"
          required=""
          style={{ height: '40px', marginTop: '20px', width: '25%' }}
          onChange={(e) => {
            setSelectedType(e.target.value);
          }}
        >
          <option>post</option>
          <option>followers</option>
          <option>impressions</option>
        </select>
      </DatepickerRow>
      {reportData && (
        <ChartWrapper>
          <Line data={data} options={options} width={100} height={250} />
        </ChartWrapper>
      )}
    </ReportWrapper>
  );
};

export default SocialReport;
